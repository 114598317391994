body {
  --primary-color: #4fc2c5;
  /*#00adff; /* #FFA000;*/
  --secondary-color: #000;
  /* #041321; #282E34;*/
  --light-color: #f4f4f4;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0rem;
  margin-left: 0rem;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

/*******************************
  * SCROLL BAR
  *******************************/
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--light-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: var(--light-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
}

/*******************************
  * SECTION
  *******************************/
section {
  padding: 70px 0 80px 0;
  overflow: hidden;
}

section .heading {
  text-align: center;
  padding-bottom: 15px;
}

.section-dark {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.section-light {
  background-color: var(--light-color);
  color: var(--secondary-color);
}

/*******************************
  *  NAV BAR
  *******************************/
.navbar {
  background-color: var(--secondary-color);
  position: fixed;
  width: 100%;
  z-index: 99;
  padding: 10px;
}

.navbar li a {
  color: var(--light-color);
}

.navbar-nav li a:hover,
.navbar-nav li.active a {
  color: var(--primary-color);
  font-weight: 900;
}

.nav-link .active {
  color: var(--primary-color) !important;
  font-weight: 900;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}
/*******************************
  *SECTION ABOUT
  *******************************/
#about {
  margin: auto;
}

#about .heading h1 {
  color: var(--primary-color);
  text-shadow: #000000d1 0.1em 0.1em 0.1em;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
}

#about .heading h3 {
  color: var(--light-color);
}

.button2 {
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  border: 1px solid white;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease-in 0s;
  background-color: var(--primary-color);
}

.button2:hover {
  background: var(--secondary-color);
  color: var(--primary-color);
  text-decoration: none;
}

.parallax-container {
  height: 100vh;
}

.parallax-background {
  height: 100%;
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imgBackgroundAbout {
  background-image: url(./images/laptop.jpg);
  /* opacity: 0.80; */
}

section .heading h2 {
  text-transform: uppercase;
}

#iconsRs {
  text-align: center;
}

.iconRs {
  font-size: 3rem;
  margin: 5px;
}

.cursor {
  font-size: 2.2rem;
  opacity: 1;
  -webkit-animation: blink 0.3s infinite;
  -moz-animation: blink 0.3s infinite;
  animation: blink 0.3s infinite;
  animation-direction: alternate;
}

.cusorHidden {
  display: none;
}

@keyframes blink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  100% {
    opacity: 0;
  }
}

/*******************************
  * SECTION SKILLS
  *******************************/
#skills .heading h2 {
  color: var(--secondary-color);
}

.sectionFront {
  margin-left: 10%;
  margin-right: 10%;
}

#skills .img-responsive {
  margin-top: 1%;
  max-height: 124px;
  max-width: 135px;
  margin-left: auto;
  margin-right: auto;
}

#icon {
  font-size: 6rem;
}

#iconDesktop {
  font-size: 8rem;
}

#iconContainer {
  margin: auto;
}

#iconContainer:hover {
  color: var(--primary-color);
}

@media screen and (max-width: 760px) {
  #iconContainer {
    display: none;
  }
}

.sectionBack {
  margin-top: 5%;
  margin-right: 10%;
  margin-left: 10%;
}

/*******************************
  * Section experience
  ******************************/
/*Time line*/
.main-timeline {
  position: relative;
  margin: 2%;
}

.main-timeline:after {
  content: '';
  display: block;
  clear: both;
}

.main-timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: ' ';
  width: 5px;
  background-color: var(--primary-color);
  left: 50%;
  margin-right: -2.5px;
}

.main-timeline .timeline {
  width: 50%;
  padding: 0 35px 0 27px;
  margin: 0 10px 20px 0;
  float: left;
}

.main-timeline .timeline-content {
  background: linear-gradient(#d9d9d9, #e7e7e7);
  text-align: center;
  padding: 15px 115px 15px 40px;
  border-radius: 20px;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.6);
  display: block;
  position: relative;
}

.main-timeline .timeline-content:hover {
  text-decoration: none;
}

.main-timeline .timeline-content:before,
.main-timeline .timeline-content:after {
  content: '';
  background: linear-gradient(to bottom, var(--primary-color), #ef5777);
  width: 45px;
  height: 45px;
  transform: rotate(-45deg);
  position: absolute;
  top: 13px;
  right: -58px;
  z-index: 1;
}

.main-timeline .timeline-content:after {
  background: var(--light-color);
  transform: rotate(-45deg) scale(0.6);
}

.main-timeline .timeline-icon {
  color: var(--light-color);
  background: linear-gradient(to right, var(--primary-color), #ef5777);
  font-size: 23px;
  text-align: center;
  line-height: 40px;
  height: 40px;
  width: 40px;
  transform: translateY(-50%) rotate(-45deg);
  position: absolute;
  top: 50%;
  left: -20px;
}

.main-timeline .timeline-icon i {
  transform: rotate(45deg);
}

.main-timeline .timeline-year {
  color: var(--light-color);
  background: linear-gradient(
    to bottom,
    var(--primary-color),
    var(--primary-color),
    #ef5777
  );
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  /* line-height: 100px; */
  height: 100%;
  width: 20%;
  border-radius: 0 0 20px 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (min-width: 768px) {
  .year {
    position: relative;
    top: 35%;
  }
}

.main-timeline .timeline-year:after {
  content: '';
  background: linear-gradient(
    to right bottom,
    var(--primary-color) 49%,
    transparent 50%
  );
  height: 33px;
  width: 33px;
  position: absolute;
  right: -33px;
  top: 0;
}

.main-timeline .title {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 10px 0;
}

.main-timeline .entreprise {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 20px 0;
}

.main-timeline .description {
  color: #333;
  font-size: 15px;
  /* text-align: left; */
  margin: 0;
}

.main-timeline .timeline:nth-child(even) {
  padding: 0 27px 0 35px;
  margin: 0 0 20px 10px;
  float: right;
}

.main-timeline .timeline:nth-child(even) .timeline-content {
  padding: 15px 40px 15px 137px;
}

.main-timeline .timeline:nth-child(even) .timeline-content:before,
.main-timeline .timeline:nth-child(even) .timeline-content:after {
  right: auto;
  left: -58px;
}

.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: auto;
  right: -20px;
}

.main-timeline .timeline:nth-child(even) .timeline-year {
  right: auto;
  left: 0;
  border-radius: 0 0 0 20px;
}

.main-timeline .timeline:nth-child(even) .timeline-year:after {
  transform: rotateY(180deg);
  right: auto;
  left: -28px;
}

@media screen and (max-width: 767px) {
  .main-timeline:before {
    left: 17px;
    transform: translateX(0);
  }

  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    padding: 0 0 27px 40px;
    margin: 0 0 30px 0;
  }

  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 70px 15px 35px 15px;
  }

  .main-timeline .timeline-content:before,
  .main-timeline .timeline-content:after,
  .main-timeline .timeline:nth-child(even) .timeline-content:before,
  .main-timeline .timeline:nth-child(even) .timeline-content:after {
    height: 25px;
    width: 25px;
    top: 10px;
    right: auto;
    left: -34px;
  }

  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    transform: translateY(0) translateX(50%) rotate(-45deg);
    left: auto;
    right: 50%;
    top: auto;
    bottom: -20px;
  }

  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 0 5px 5px 5px;
    right: auto;
    left: 0;
  }

  .main-timeline .timeline-year:after,
  .main-timeline .timeline:nth-child(even) .timeline-year:after {
    height: 20px;
    width: 20px;
    transform: rotateY(180deg);
    right: auto;
    left: -16px;
  }
}

@media screen and (max-width: 576px) {
  .main-timeline .title {
    font-size: 18px;
  }
}

/*******************************
  * SECTION STUDY
  *******************************/
.education-block {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
  background: #ddd;
  border-radius: 30px;
  border: 2px solid #ccc;
  margin-bottom: 20px;
}

.education-block h5 {
  color: #888;
  font-size: 15px;
  margin-bottom: 15px;
}

.education-block h5 .glyphicon {
  font-size: 40px;
}

.education-block h2 {
  font-size: 1.5rem;
}

.education-block h3 {
  color: var(--primary-color);
  margin-bottom: 20px;
}

.education-block h4 {
  margin-bottom: 20px;
  font-weight: 500;
}

.education-block p {
  font-weight: 500;
}

.education-block .red-divider {
  margin-bottom: 20px;
}

/*
  *PORTfolio
  */

#portfolio .heading h2 {
  color: var(--primary-color);
}

.box {
  margin-bottom: 3%;
}

.portfolio-container .box:hover .link {
  width: 100%;
  display: block;
  height: 100%;
}

.portfolio-container .link {
  right: 0;
  bottom: 0;
  background: var(--primary-color);
  color: var(--light-color);
  opacity: 0.7;
  font-size: 25px;
  padding: 0 0 0;
  text-align: center;
  position: absolute;
  width: 100%;
  display: block;
  height: 0px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.portfolio-container .link span {
  font-size: 120px;
  font-weight: 100;
  height: 40px;
  display: block;
  font-family: 'Raleway', Georgia, 'Times New Roman', Times, serif;
  margin-top: 50px;
  color: var(--light-color);
}

.portfolio-container .link:hover {
  color: #2a2a2a;
  text-decoration: none;
}

.portfolio-container .text-box {
  z-index: 100;
  position: relative;
  padding: 11px 18px 6px;
  border-radius: 0px 0px 20px 20px;
  background: var(--light-color);
  color: #666;
}

.portfolio-container .text-box::after {
  content: '';
  position: absolute;
}

.portfolio-container .text-box:after {
  left: 21px;
  top: -14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 16px 7px;
  border-radius: 0px 0px 20px 20px;
  border-color: transparent transparent #f8f8f8 transparent;
}

.portfolio-container .thumbnail {
  overflow: hidden;
  position: relative;
  border-radius: 20px 20px 0px 0px;
}

#portfolio .thumbnail img {
  width: 100%;
  height: 100%;
  max-height: 227px;
  min-height: 227px;
  min-width: 319px;
}

#portfolio .container-fluid {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

#portfolio .thumbnail img:hover {
  filter: none;
  -webkit-filter: none;
}

.portfolio-container .box:hover .text-box {
  background: #ddd;
  color: #666;
}

.portfolio-container .box:hover .text-box:after {
  border-color: transparent transparent #ddd transparent;
}

/*******************************
  *SECTION CONTACT
  *******************************/
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

@media screen and (min-width: 760px) {
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
}

.contact-form {
  color: var(--primary-color);
  font-size: 20px;
  background: var(--secondary-color);
  padding: 40px;
  border-radius: 10px;
}

.g-recaptcha {
  visibility: hidden;
}

/******************************
  * IMAGES SEPARATION
  *******************************/
.sticky-footer {
  background-color: var(--secondary-color);
}

footer.sticky-footer .copyright {
  color: var(--primary-color);
  padding: 15px;
}

/*******************************
  * IMAGES SEPARATION
  ********************************/
.pximg1,
.pximg2,
.pximg3,
.pximg4,
.pximg5 {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.pximg1 {
  background-image: url('./images/breakImage/laptop2.jpg');
  min-height: 300px;
}

.pximg2 {
  background-image: url('./images/breakImage/livre.jpg');
  min-height: 300px;
}

.pximg3 {
  background-image: url('./images/breakImage/workplace.jpg');
  min-height: 300px;
}

.pximg4 {
  background-image: url('./images/breakImage/staff.jpg');
  min-height: 300px;
}

.pximg5 {
  background-image: url('./images/breakImage/landscape.jpg');
  min-height: 300px;
}

.layer-filter {
  background-color: rgba(0, 0, 0, 38%);
  width: 100%;
  min-height: 300px;
  display: flex;
}

.pxtext {
  background-color: var(--secondary-color);
  margin: auto;
  max-width: 70%;
  text-align: center;
  color: var(--primary-color);
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 0.5rem;
}

.noMb {
  margin: 0;
}
